<template>
    <div v-if="board" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ board.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>{{ $t("common.작성일") }} : {{ board.createdAt.toDate() }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{ $t("common.조회수") }} : {{ board.viewCount }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr v-if="board?.files?.[0]">
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            {{$t("common.attachments")}} : <v-icon>mdi-paperclip</v-icon>
                            <a :href="board.files[0].url" download style="text-decoration: underline">{{ board.files[0].fileName }}</a>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-html="board.content"></div>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="board-bottom">
            <v-row justify="end" class="row--x-small">
                <v-col v-show="false" cols="auto">
                    <v-row justify="end" class="row--x-small">
                        <v-col cols="auto">
                            <v-btn color="primary" class="w-100px">수정</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary" class="w-100px">삭제</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn outlined color="primary" class="w-100px">답글</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="grey-d6" class="w-140px" @click="$router.go(-1)">{{ $t("common.list") }}</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import CommentList from "../../comment-list.vue";

export default {
    components: {
        CommentList,
    },
    props: {
        board: { type: Object, default: null },
    },
    data() {
        return {
            data: undefined,
            show: false,
        };
    },
    watch: {
        board() {
            if (this.board) this.show = true;
        },
    },
};
</script>
